(function () {
    'use strict';

    angular.module('aerosApp')
        .directive('aflModalCarouselInput', aflModalCarouselInput);

    aflModalCarouselInput.$inject = ['$timeout'];

    function aflModalCarouselInput($timeout) {
        var directive = {
            restrict: 'E',
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-modal-carousel-input/afl-modal-carousel-input.html',
            controller: aflModalCarouselInputController,
            scope: {
                group: '=',
                generic: '=',
                fieldDescription: '=',
                parentFieldDescription: '=',
                model: '=',
                mode: '=',
                fieldName: '=',
                parentFieldName: '=',
                required: '=',
                units: '=',
                visible: '&',
                fieldChangeEventHandler: '&'
            },
            link: function (scope, element, attrs, controller) {
                setDefaultItemLink(scope);
            }
        };

        return directive;
    }

    function setDefaultItemLink(scope) {
        if (!scope.model[scope.fullDotName] && scope.fieldDescription
            && typeof scope.fieldDescription.default !== 'undefined') {
            if ((scope.fieldDescription.type === 'list' || scope.fieldDescription.type === 'integer'
                || scope.fieldDescription.type === 'checkboxes')
                && !scope.fieldDescription.findNearestOnParentChange) {
                scope.model[scope.fullDotName] = scope.fieldDescription.default;
            }
        }
    }

    aflModalCarouselInputController.$inject = ['$scope', 'UnitsMeasurementService'];

    function aflModalCarouselInputController($scope, UnitsMeasurementService) {

        angular.extend($scope, {
            getListItemByModel: getListItemByModel,
            showListInViewMode: showListInViewMode,
            isEditable: isEditable,
            isDisabled: isDisabled,
            inputFieldChangeEventHandler: inputFieldChangeEventHandler
        });

        (function init() {
            /*if ($scope.model && $scope.generic != undefined && $scope.model.formInputData == undefined) {
                $scope.model.formInputData = $scope.generic;
            }*/
            $scope.altValue = {};

            if ($scope.fieldDescription && $scope.fieldDescription.type === 'float_unit' && $scope.fieldDescription.units) {
                $scope.unitCaptions = $scope.fieldDescription.units.split('|');
                $scope.converters = UnitsMeasurementService.getConverters($scope.fieldDescription.units);
            }

            if ($scope.fieldDescription && $scope.fieldDescription.type === 'float_unit' && $scope.fieldDescription.decimalPlaces) {
                $scope.decimalPlaces = $scope.fieldDescription.decimalPlaces.split('|');
            }

            if ($scope.fieldDescription && $scope.fieldDescription.type === 'float_unit' && $scope.fieldDescription.steps) {
                $scope.steps = $scope.fieldDescription.steps.split('|');
            }

            if ($scope.units) {
                $scope.model.units = $scope.units.name; // <- hardcoded initialization in the view set to 'feet'!!!
            }

            $scope.fullDotName = ($scope.group && $scope.model.units) || ($scope.group && $scope.model.type)
                ? $scope.group.id + "." + $scope.fieldName
                : $scope.fieldName;

            $scope.isVisible = typeof $scope.visible !== "undefined" ?  $scope.visible : true;
            $scope.Math = Math;

            setDefaultItem();

        })();

        function setDefaultItem() {
            if ($scope.mode !== 0 && !$scope.model[$scope.fullDotName]) {
                switch ($scope.model.toolType) {
                    case 'OTDR' :
                        setDefaultFromGeneric($scope.model, $scope.fullDotName,
                            $scope.generic[$scope.model.fiberMode]);
                        setDefaultItemLink($scope);
                        break;
                }
            }
        }

        function setDefaultFromGeneric(model, fieldName, generic) {
            if (typeof model[fieldName] === 'undefined') {
                model[fieldName] = generic[fieldName];
            }
        }

        function showListInViewMode(item) {
            return ($scope.mode === 0 || $scope.mode === 'VIEW')
                && (typeof $scope.model[$scope.fullDotName] !== 'undefined')
                && item.value == $scope.model[$scope.fullDotName];
        }

        function getListItemByModel() {
            return $scope.fieldDescription.values.find(function(item) {
                return item.value === $scope.model[$scope.fullDotName];
            })
        }

        function isEditable() {
            return $scope.mode !== 0 && $scope.mode.toString().toLowerCase() !== 'view';
        }

        function isDisabled() {
            return ($scope.mode === 2 || $scope.mode.toString().toLowerCase() === 'edit')
                && $scope.fieldDescription.disabledOnEdit;
        }

        function inputFieldChangeEventHandler(properties) {
            $scope.fieldChangeEventHandler({properties: properties});
        }

    }
})();
